<template>
  <div>
    <TableFilter
      v-if="modalIsActive && !preview"
      :preload="filter"
      @close="toggleFilter"
      :range="true"
      @save="setFilter"
    />
    <div class="columns is-vcentered is-multiline">
      <div class="column">
        <div class="columns is-mobile is-multiline is-vcentered">
          <div
            class="column is-narrow"
            v-if="rows"
          >
            <p class="table-options-lookup">
              Lookup history
            </p>
          </div>
          <div 
            class="column is-narrow"
            v-if="rows"
          >
            <p
              class="table-options-showing"
              v-if="title"
            >
              {{ title }}
            </p>
          </div>
          <div
            class="column is-narrow"
            v-if="preview"
          >
            <router-link
              @click.native="track"
              :disabled="!rows"
              :to="{ name: 'account-activity-user' }"
              class="table-options-viewall"
            >
              View all
            </router-link>
          </div>
        </div>
      </div>
      <div 
        v-if="!preview" 
        class="column is-narrow"
      >
        <div class="field is-grouped">
          <div class="control">
            <TableSearch
              @change="setSearch"
              placeholder="Start typing to search"
            />
          </div>
          <div class="control">
            <date-picker-button
              @toggleFilter="toggleFilter"
              @clearFilter="clearFilter"
              :to="query.to"
              :from="query.from"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-vcentered is-multiline is-mobile">
      <div 
        class="column is-12" 
        v-if="depth === 'branch'"
      >
        <SummaryBoxes
          v-if="usage"
          v-bind="{
            usage,
            isCompanionUser,
            hasAutoCheck
          }"
        />
      </div>
      <div class="column is-12">
        <Tabs
          v-bind="{
            hasAutoCheck,
            tab,
            preview,
            colour
          }"
          @change-tab="changeTab"
          data-test-id="product-tabs"
        />
      </div>
    </div>
    <p
      class="has-text-hard is-size-7"
      v-if="!isEmpty && tab === 'account-history-vpp'"
    >
      <span class="icon is-small">
        <i class="fal fa-info-circle" />
      </span>
      <span>Clicking on a previous search will count as a separate lookup</span>
    </p>
    <table
      class="table is-fullwidth is-size-7-mobile is-striped is-transparent"
      v-if="!isEmpty"
    >
      <TableHeader
        v-bind="{ columns, sort, preview, depth }"
        @change="setSort"
      />
      <component
        :is="tab"
        v-bind="{ preview, depth, rows, columns }" 
      />
    </table>
    <TableEmpty
      v-else
      text="Nothing to see here"
      subtext="We can't find any searches for you"
    />
    <Pagination
      v-if="!isEmpty && !preview"
      :fn="'fetch'"
      v-bind="{ page, pages }"
      @change="setPage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tableSearch from 'core/modules/table-search/mixins/tableSearch.mixin'
import tableFilter from 'core/modules/table-filter/mixins/tableFilter.mixin'
import tableHeader from 'core/modules/table-header/mixins/tableHeader.mixin'
import pagination from 'core/modules/pagination/mixins/pagination.mixin'
import activityTable from 'core/mixins/activityTable'
import * as account from 'modules/account/services'
export default {
  name: 'AccountHistoryTable',
  components: {
    AccountHistoryCheck: () => import('./AccountHistoryCheck'),
    AccountHistoryVpp: () => import('./AccountHistoryVpp'),
    AccountHistoryCompanion: () => import('./AccountHistoryCompanion'),
    TableSearch: () =>
      import('core/modules/table-search/components/TableSearch'),
    TableFilter: () =>
      import('core/modules/table-filter/components/TableFilter'),
    TableHeader: () =>
      import('core/modules/table-header/components/TableHeader'),
    TableEmpty: () => import('core/components/TableEmpty'),
    DatePickerButton: () =>
      import('core/modules/table-filter/components/DatePickerButton'),
    Tabs: () => import('./AccountHistoryProductTabs'),
    SummaryBoxes: () => import('./AccountHistorySummaryBoxes')
  },
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    tab: '',
    response: null,
    usage: null
  }),
  mixins: [
    activityTable,
    pagination({
      fn: {
        data: 'response',
        fetch: 'fetch'
      }
    }),
    tableSearch({ fetch: 'fetch' }),
    tableFilter({ fetch: 'fetch' }),
    tableHeader({ fetch: 'fetch' })
  ],
  created() {
    const preselect = this.$route.query.tab

    const tabs = {
      autocheck: 'account-history-check',
      companion: 'account-history-companion',
      vpp: 'account-history-vpp'
    }

    if (preselect) {
      this.tab = tabs[preselect]
    } else {
      this.tab = this.$experian
        ? tabs.autocheck
        : this.isCompanionUser
        ? tabs.companion
        : tabs.vpp
    }

    this.fetch()
  },
  computed: {
    ...mapGetters('auth', [
      'isCompanionUser',
      'hasAutoCheck',
      'branch',
      'organisation',
      'hasCustomerReference'
    ]),
    icon() {
      if (this.depth === 'org') return 'fa-university'
      if (this.depth === 'branch') return 'fa-building'
      else return 'fa-search'
    },
    colour() {
      return this.tab === 'account-history-companion' ? 'orange' : 'celadon'
    },
    total() {
      return this.response?.total
    },
    title() {
      return `Showing ${this.rows?.length} of ${this.total}`
    },
    service() {
      const map = {
        'account-history-companion': 'getCompanion',
        'account-history-check': 'getChecks',
        'account-history-vpp': 'getVpp',
        'account-history-auto-check-valuation': 'getAutoCheckValuation'
      }
      return map[this.tab]
    },
    isEmpty() {
      return this.response?.results?.length === 0
    },
    check() {
      return [
        { label: 'VRM', type: 'vrm', query: true },
        { label: 'Vehicle description', hideOnMobile: true },
        { label: 'Date', type: 'createdAt', query: true },
        { label: 'Check status' },
        { label: 'Actions' }
      ]
    },
    companion() {
      return [
        { label: 'VRM', type: 'vrm', query: true },
        { label: 'Reference', hideOnMobile: true },
        { label: 'Vehicle description', hideOnMobile: true },
        { label: 'Trade value', hideOnMobile: true },
        { label: 'Retail value', hideOnMobile: true },
        { label: 'Mileage', hideOnMobile: true },
        { label: 'Date', type: 'createdAt', query: true },
        { label: 'Actions' }
      ]
    },
    autoCheckValuation() {
      return [
        { label: 'VRM', type: 'vrm', query: true },
        { label: 'Vehicle description', hideOnMobile: true },
        { label: 'Mileage', hideOnMobile: true },
        { label: 'Date', type: 'createdAt', query: true },
        { label: 'Cazana' },
        { label: 'Glass' },
        { label: 'CAP' },
        { label: 'Actions' }
      ]
    },
    vpp() {
      return [
        { label: 'VRM', type: 'vrm', query: true },
        { label: 'Vehicle description', hideOnMobile: true },
        { label: 'Mileage', hideOnMobile: true },
        { label: 'Date', type: 'createdAt', query: true },
        { label: 'Actions' }
      ]
    },
    columns() {
      const { tab, depth, check, companion, autoCheckValuation, vpp } = this
      const user = { label: 'User', hideOnMobile: true }
      const branch = { label: 'Branch', hideOnMobile: true }

      if (tab === 'account-history-check') {
        if (depth === 'org') {
          return [...check.slice(0, 3), user, branch, ...check.slice(3)]
        } else if (depth === 'branch') {
          return [...check.slice(0, 3), user, ...check.slice(3)]
        } else {
          return check
        }
      } else if (tab === 'account-history-companion') {
        if (depth === 'org') {
          return [...companion.slice(0, 6), user, branch, ...companion.slice(6)]
        } else if (depth === 'branch') {
          return [...companion.slice(0, 6), user, ...companion.slice(6)]
        } else {
          return companion
        }
      } else if (tab === 'account-history-auto-check-valuation') {
        if (depth === 'org') {
          return [
            ...autoCheckValuation.slice(0, 7),
            user,
            branch,
            ...autoCheckValuation.slice(7)
          ]
        } else if (depth === 'branch') {
          return [
            ...autoCheckValuation.slice(0, 7),
            user,
            ...autoCheckValuation.slice(7)
          ]
        } else {
          return autoCheckValuation
        }
      } else {
        if (depth === 'org') {
          return [...vpp.slice(0, 4), user, branch, ...vpp.slice(4)]
        } else if (depth === 'branch') {
          return [...vpp.slice(0, 4), user, ...vpp.slice(4)]
        } else {
          return vpp
        }
      }
    },
    limit() {
      return this.preview ? 5 : 12
    },
    rows() {
      return this.response?.results
    }
  },
  methods: {
    track() {
      this.$mxp.track('dashboard_user_history_view_all')
    },
    async fetch(req) {
      const { limit, query, depth } = this

      try {
        this.$wait.start('response')
        if (depth === 'branch') this.getBranchUsage()

        const params = { limit, depth, ...query, ...req }
        this.response = await account[this.service](params)
      } catch (err) {
        if (err?.response?.status !== 401) {
          this.$notify('History unavailable')
        }
      } finally {
        this.$wait.end('response')
      }
    },
    toggleFilter() {
      this.$mxp.track(`${this.depth}_history_companion_open_date_filter`)
      this.modalIsActive = !this.modalIsActive
    },
    changeTab(tab) {
      if (tab === 'account-history-companion' && !this.isCompanionUser) {
        return this.$modal.open('companion/UpsellCompanion')
      }
      this.tab = tab
      this.page = 1
      this.fetch()
    },
    async getBranchUsage() {
      this.$wait.start('usage')
      const { from, to } = this.query
      try {
        this.usage = await account.getBranchUsage({ from, to })
      } catch (err) {
        if (err?.response?.status !== 401) this.$notify('Usage unavailable')
      } finally {
        this.$wait.end('usage')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs
  overflow-x: hidden
.table-options-lookup
  font-weight: $history-table-options-lookup-font-weight
  color: $history-table-options-lookup-text-color
.table-options-showing
  font-weight: $history-table-options-showing-font-weight
  color: $history-table-options-showing-text-color
.table-options-viewall
  text-decoration: $history-table-options-text-decoration
  font-weight: $history-table-options-viewall-font-weight
  color: $history-table-options-viewall-text-color
  &:hover
    color: $history-table-options-viewall-text-hover
</style>
