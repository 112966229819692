export default ({ fetch }) => ({
  data: () => ({
    modalIsActive: false,
    filter: null
  }),
  methods: {
    setFilter(value) {
      this.page = 1
      this.filter = value
      this[fetch]()
    },
    toggleFilter() {
      this.$store.commit('background/toggle')
      this.modalIsActive = !this.modalIsActive
    },
    clearFilter() {
      this.page = 1
      this.filter = {
        date: null
      }
      this[fetch]()
    }
  }
})
