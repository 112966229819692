export default ({ fetch }) => ({
  data: () => ({
    sort: {
      type: null,
      order: null
    }
  }),
  methods: {
    toggleSelect($event) {
      return $event ? this.selectAll() : this.deselectAll()
    },
    setSort(type) {
      if (this.sort.type !== type) {
        this.sort.type = type
        if (this.sort.order === null) {
          this.sort.order = 'asc'
        }
      } else {
        if (this.sort.order === 'desc') {
          this.sort.type = null
        }
        this.sort.order =
          this.sort.order === null
            ? 'asc'
            : this.sort.order === 'asc'
            ? 'desc'
            : null
      }

      if (this.page) {
        this.page = 1
      }

      this[fetch]()
    }
  }
})
