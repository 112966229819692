import { get } from 'lodash'

export default {
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    limit() {
      return this.preview ? 5 : 12
    },
    query() {
      return {
        page: this.page,
        limit: this.limit,
        sort: get(this.sort, 'type'),
        order: get(this.sort, 'order'),
        search: this.search,
        from: get(this.filter, 'date.start'),
        to: get(this.filter, 'date.end')
      }
    },
    isEmpty() {
      return this.rows && this.rows.length === 0
    }
  }
}
