export default ({ fetch }) => ({
  data: () => ({
    search: ''
  }),
  components: {
    'table-search': () => import('../../table-search/components/TableSearch')
  },
  methods: {
    setSearch(search) {
      this.page = 1
      this[fetch]({ search }).then(() => (this.search = search))
    }
  }
})
