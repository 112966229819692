import { get } from 'lodash'

export default ({ fn }) => ({
  data: () => ({
    page: 1
  }),
  components: {
    Pagination: () => import('../components/Pagination')
  },
  computed: {
    pages() {
      return get(this[fn.data], 'pages', 0)
    }
  },
  methods: {
    async setPage(page) {
      if (page > 0 && page <= this.pages) {
        if (fn.fetch) {
          await this[fn.fetch]({ page })
        }
        this.page = page
      }
    }
  }
})
